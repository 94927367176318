<template>

  <div class="updateProgress">
    <rxNavBar title="更新进度"></rxNavBar>
    <!--上传图片loading-->
    <loading v-if="loadingFlag"></loading>
    <van-tabs v-model="outerActive"
              line-width="20"
              line-height="5"
              title-active-color="rgb(255,81,45)"
              sticky
              offset-top="1.2195121951219512rem"
              @click-tab="onClickTab"
    >
      <van-tab title="详情">
        <div class="firstTitle"><span>合同详情</span></div>
        <div class="container-normal detailInfo ">
          <div class="font-bold addLineHigh">地址：<span
              class="font-normal ">{{ initUpdateProgressData.contractInfo.dispatchAddress }}</span>
          </div>
          <div class="font-bold addLineHigh">合同编号：<span
              class="font-normal orangeColor">{{ initUpdateProgressData.contractInfo.contractCode }}</span>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left ">业主：<span
                class="font-normal ">{{ initUpdateProgressData.contractInfo.ownerName }}</span>
            </div>
            <div class="font-bold float-Right add-pos">面积：<span
                class="font-normal ">{{ initUpdateProgressData.contractInfo.builtUpArea + '㎡' }}</span>
            </div>
          </div>
          <div class="font-bold addLineHigh">收房年限：<span
              class="font-normal ">{{ initUpdateProgressData.contractInfo.contractYears + '年' }}</span>
          </div>
        </div>
        <div class="leftTitle"><span>申请详情</span></div>

        <div class="container-normal detailInfo">
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">申请人：<span
                class="font-normal ">{{ initUpdateProgressData.headList.applicant }}</span>
            </div>
            <div class="font-bold float-Right add-pos">联系方式：<span
                class="font-normal ">{{ initUpdateProgressData.headList.applicantMobile }}</span>
            </div>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">设计师：<span
                class="font-normal ">{{ initUpdateProgressData.headList.designGlobaluserName }}</span>
            </div>
            <div class="font-bold float-Right add-pos">类型：<span
                class="font-normal ">{{ initUpdateProgressData.headList.isFirst == '1' ? '首次' : '二次' }}</span>
            </div>
          </div>
          <div class="twoCol addLineHigh">
            <div class="font-bold float-Left">装修预算：<span
                class="font-normal redColor">{{ initUpdateProgressData.headList.budget + '元' }}</span>
            </div>
            <div class="font-bold float-Right add-pos">交房日期：<span
                class="font-normal ">{{ initUpdateProgressData.headList.handoverDate }}</span>
            </div>
          </div>
          <div class="font-bold addLineHigh-auto" style="margin-top: 2px">装修需求：<span
              class="font-normal ">{{ initUpdateProgressData.headList.demand }}</span>
          </div>
          <div class="font-bold addLineHigh-auto">装修描述：<span
              class="font-normal  ">{{ initUpdateProgressData.headList.afterRentDesprition }}</span>
          </div>
        </div>
        <!--                v-if="imgList.length"-->
        <div class="container-imgs add-Margin-Top" v-if="imgList.length">
          <div class="swiper_show" ref="wrapperBox_house">
            <div class="content">
              <div class="imgWrap" v-for="(url,i) in imgList" @click="previewImg(i)">
                <van-image
                    width="90"
                    height="80"
                    fit="contain"
                    radius="10"
                    :src="url">
                </van-image>
                <div class="num">
                  {{ imgList.length }}图
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="leftTitle"><span>装配明细</span></div>
        <div class="innerTabs">
          <img class="tickletf" :src="innerActive==0?grassgreenTick:grayTickImg"/>
          <img class="tickRight" :src="innerActive==1?grassgreenTick:grayTickImg"/>
          <van-tabs v-model="innerActive"
                    line-width="20"
                    line-height="5"
                    title-active-color="rgb(255,81,45)"
                    swipeable
                    sticky
                    offset-top="1.2195121951219512rem"
          >
            <van-tab title="硬装方案">
              <div class="container-normal header detailInfo-inner">
                <div class="font-bold littlelineHigh">硬装负责人：<span
                    class="font-normal ">{{ initUpdateProgressData.hardChargeName }}</span>
                </div>
                <div class="font-bold littlelineHigh ">硬装首期占比：<span
                    class="font-normal ">{{ initUpdateProgressData.hardInpart*100 }}%</span>
                </div>
                <div class="font-bold addSmallineHigh">预计工期：<span
                    class="font-normal ">{{
                    initUpdateProgressData.hardAppointmentBeginTime
                  }} 至 {{ initUpdateProgressData.hardAppointmentEndTime }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计时长：<span
                    class="font-normal ">{{ initUpdateProgressData.handInquest + '天' }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计硬装费用：<span
                    class="font-normal ">{{ initUpdateProgressData.hardfeePrice + '元' }}</span>
                </div>
              </div>
              <div class="dividing-line"></div>

              <div class="container-line" style="display: flex;flex-flow: row nowrap">
                <span class="costTitle">实际硬装费用：</span>
                <!--                                若是未点完成显示的费用为预计费用-->
                <span class="font-bold redColor">{{sethardrealfeePrice}}</span>
                <!--                                {{initUpdateProgressData.hardRealFee}}-->
                <!--<van-button class="hardFit-button" @click="toHeardFinish" v-if="!hardDisabled">硬装完成</van-button>-->

              </div>
        <van-overlay :show="hardShow" @click="hardShow = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <div class="getPassword">
                        确认完成吗？
                    </div>
                    <div class="LockPassWord">
                        <p class="password" @click="softFinish('0')">确认 </p>
                    </div>
                </div>
                <div class="closeImg" @click="closeHard">
                </div>
            </div>
        </van-overlay>

              <div class="part-inputpart-row" >
                <span :class=" !initUpdateProgressData.hardBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardBeginTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <!--<div class="part-inputpart-row" v-if="hardDisabled">
                <span :class=" !initUpdateProgressData.hardBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardBeginTime | formatDateTime
                  }}</span>
              </div>-->
              <div>
                <van-popup v-model="ishcStartShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="starthcConfirm"
                      @cancel="starthcCancel"
                  />
                </van-popup>
              </div>
              <div class="part-inputpart-row"  >
                <span :class=" !initUpdateProgressData.hardEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardEndTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <!--<div class="part-inputpart-row" v-if="hardDisabled">
                <span :class=" !initUpdateProgressData.hardEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.hardEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.hardEndTime | formatDateTime
                  }}</span>
              </div>-->
              <div>
                <van-popup v-model="ishcEndShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="endhcConfirm"
                      @cancel="endhcCancel"
                  />
                </van-popup>
              </div>
              <div class="container-line" >
                  <van-button class="saveButton_Enable" @click="startOrder('0')" style="float:right" v-if="!initUpdateProgressData.hardBeginTime">开始完成</van-button>
              </div>
              <div style="clear:both"></div>
        <van-collapse v-model="abc">
            <van-collapse-item v-for="item in hardMiddleList" :name="item.middlesumName" :key="item.id" :title='item.middlesumName' class="deploy-setting" style="border-bottom-right-radius: 10px">
               <div class="contract" v-for="little,index in hardLittleList" :key="index" v-if="item.goodsallocationmiddlesumId==little.goodsallocationmiddlesumId">
                    <div class="program">
                        <div class="position">{{little.itemName}}</div>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="24">
                                <p>位置区域：<span>{{ little.regionalLocationName?little.regionalLocationName:little.roomName  }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>数量：<span>{{ little.totalCount }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>预计金额：<span>{{ little.estimateMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>实际金额：<span>{{ little.realMoney }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>备注：<span>{{ little.remarks }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                        <div class="bottom">
                          <van-button style="float:right" class="bottom-property" @click="hardComplete(little)"  v-if="little.status=='1'&&checkAuthList(updateList,'renovationfinish')" :disabled="enable">完成</van-button>
                            <!--<div style="float:right" class="bottom-property" @click="hardComplete(little)"  v-if="little.status=='1'&&checkAuthList(updateList,'renovationfinish')">完成</div>-->
                        </div>
                    </div>
               </div>
            </van-collapse-item>
        </van-collapse>
              <div class="container-line" v-if="!hardTotalFinish">
                  <van-button class="saveButton_Enable" @click="changeApply('0')" style="float:left">变更申请</van-button>
                  <van-button class="saveButton_Enable" @click="finishAllHardSoft('0')" style="float:right" :disabled="enable">硬装完成</van-button>
              </div>
              <div style="clear:both"></div>
            </van-tab>
            <van-tab title="软装方案">
              <div class="container-normal header detailInfo-inner">
                <div class="font-bold littlelineHigh">软装负责人：<span
                    class="font-normal ">{{ initUpdateProgressData.softChargeName }}</span>
                </div>
                <div class="font-bold littlelineHigh ">软装首期占比：<span
                    class="font-normal ">{{ initUpdateProgressData.softInpart*100 }}%</span>
                </div>
                <div class="font-bold addSmallineHigh">预计工期：<span
                    class="font-normal ">{{
                    initUpdateProgressData.softAppointmentBeginTime
                  }} 至 {{ initUpdateProgressData.softAppointmentEndTime }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计时长：<span
                    class="font-normal ">{{ initUpdateProgressData.softInquest + '天' }}</span>
                </div>
                <div class="font-bold addSmallineHigh">预计软装费用：<span
                    class="font-normal ">{{ initUpdateProgressData.softfeePrice + '元' }}</span>
                </div>
              </div>
              <div class="dividing-line"></div>
              <div class="container-line" style="display: flex;flex-flow: row nowrap">
                <span class="costTitle">实际软装费用：</span>
                <span class="font-bold redColor">{{ setsoftfeePrice }}</span>
              </div>
              <div class="part-inputpart-row"  >
                <span :class=" !initUpdateProgressData.softBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softBeginTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <!--<div class="part-inputpart-row" v-if="softDisabled">
                <span :class=" !initUpdateProgressData.softBeginTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际开始日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softBeginTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softBeginTime | formatDateTime
                  }}</span>
              </div>-->
              <div>
                <van-popup v-model="issoStartShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="startsoConfirm"
                      @cancel="startsoCancel"
                  />
                </van-popup>
              </div>
              <div class="part-inputpart-row" >
                <span :class=" !initUpdateProgressData.softEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softEndTime | formatDateTime
                  }}</span>
                <img class="part-inputpart-row-right part-inputpart-row-right-timepick"
                     src="../../assets/images/timepick.png">
              </div>
              <!--<div class="part-inputpart-row" v-if="softDisabled">
                <span :class=" !initUpdateProgressData.softEndTime ? 'content-none' : 'content-have' "></span>
                <span class="part-inputpart-row-header">实际结束日期:</span>
                <span class="content-divide">|</span>
                <span class="part-inputpart-row-graytext"
                      :class="0==initUpdateProgressData.softEndTime ? '': 'part-inputpart-row-redtext' ">{{
                    initUpdateProgressData.softEndTime | formatDateTime
                  }}</span>
              </div>-->
              <div>
                <van-popup v-model="issoEndShow" position="bottom">
                  <van-datetime-picker
                      v-model="currentDate"
                      type="date"
                      title="选择年月日"
                      item-height="35px"
                      @confirm="endsoConfirm"
                      @cancel="endsoCancel"
                  />
                </van-popup>
              </div>
              <div class="container-line" >
                  <van-button class="saveButton_Enable" @click="startOrder('1')" style="float:right" v-if="!initUpdateProgressData.softBeginTime ">开始接单</van-button>
              </div>
        <van-collapse v-model="abc">
            <van-collapse-item v-for="item in softMiddleList" :name="item.middlesumName"  :key="item.id" :title='item.middlesumName' class="deploy-setting" style="border-bottom-right-radius: 10px">
               <div class="contract" v-for="little in softLittleList" :key="little.id" v-if="item.goodsallocationmiddlesumId==little.goodsallocationmiddlesumId">
                    <div >
                        <div class="position">{{little.itemName}}</div>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="24">
                                <p>位置区域：<span>{{ little.regionalLocationName?little.regionalLocationName:little.roomName  }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>数量：<span>{{ little.totalCount }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>预计金额：<span>{{ little.estimateMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>实际金额：<span>{{ little.realMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>供应商：<span>{{ little.newsupplierName }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>加项原因：<span>{{ little.reasonAdditionTitle }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>备注：<span>{{ little.remarks }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                        <div class="bottom">
<!--                          <van-button class="bottom-property" style="float:right" :disabled="saveingStatus"  @click="softComplete(little)" v-if="little.status=='1'&&checkAuthList(updateList,'renovationfinish')">完成</van-button>-->
                          <van-button class="bottom-property" style="float:right" :disabled="saveingStatus"  @click="editSupplier(little)" v-if="little.status=='0'&&checkAuthList(updateList,'renovationreceive')">接单</van-button>
                            <!--<div style="float:right" class="bottom-property" @click="hardComplete(little)"  v-if="little.status=='1'&&checkAuthList(updateList,'renovationfinish')">完成</div>    -->
                        </div>
                    </div>
               </div>
            </van-collapse-item>
        </van-collapse>
              <div class="container-line" v-if="!softTotalFinish">
                  <van-button class="saveButton_Enable" @click="changeApply('1')" style="float:left">变更申请</van-button>
                  <van-button class="saveButton_Enable" @click="finishAllHardSoft('1')" style="float:right" :disabled="enable">软装完成</van-button>
              </div>
            </van-tab>
          </van-tabs>
        </div>
        <div class="leftTitle"><span>施工图片</span></div>
        <div class="part-inputpart">
          <div class="part-uploadpic-content" ref="wrapperBox_build">
                  <div class="imgWrap" v-for="(item,i) in initUpdateProgressData.updatePictures" :key="item.path"  @click="previewConstructImg(i)">
                      <van-image
                          width="90"
                          height="80"
                          fit="contain"
                          radius="10"
                          :src="item.path">
                      </van-image>
                      <!-- <div class="deletePictures" @click="deletePictures()">
                        删除
                      </div> -->
                  </div>
                <van-uploader v-model="buildNewImg"
                              upload-icon="plus"
                              :max-count="50"
                              multiple
                              image-fit="contain"
                              :after-read="afterReadTest"
                              v-if="editConstructImg"
                >
                </van-uploader>

                <!-- <img  src="https://himihome.oss-cn-qingdao.aliyuncs.com/files/2023/2/images/platform/templatetmp/20232/fc0806d9-915c-4130-a551-0ae121501e98.jpg" alt=""> -->

                <div class="container-line">
                    <van-button class="saveButton_Enable" @click="saveConstructImg()" style="float:right" v-if="editConstructImg">图片保存</van-button>
                    <van-button class="saveButton_Enable" @click="editConstruct()" style="float:right" v-else>上传图片</van-button>

                </div>
          </div>
        </div>

        <div class="leftTitle"><span>实际费用</span></div>
        <div class="container-cost ">
          <div class="costLine addTop ">
            <span class="font-bold float-Left ">硬装：</span>
            <div style="flex: auto"></div>
<!--            <input class="font-normal float-Right kd" style="border: none" :value="sethardrealfeePrice+'元'">-->
            <span class="font-normal float-Right kd" style="border: none">{{sethardrealfeePrice+'元'}}</span>
            <!--                            {{initUpdateProgressData.hardRealFee}}</span>-->
          </div>
          <div class="costLine addBottom">
            <span class="font-bold float-Left">软装：</span>
            <div style="flex: auto"></div>
<!--            <input class="font-normal float-Right kd" style="border: none" :value="setsoftfeePrice+'元'">-->
            <span class="font-normal float-Right kd" style="border: none">{{setsoftfeePrice+'元'}}</span>
            <!--                            ￥{{initUpdateProgressData.softRealFee}}</span>-->
          </div>
          <div class="dividing-line"></div>
          <div class="sumLine addBottom">
            <span class="font-bold float-Left">总实际费用：</span>
<!--            <input class="font-bold float-Right kd" style="border: none;height: 40px" :value="setTotalRealFee+'元'">-->
            <span class="font-bold float-Right kd" style="border: none;height: 40px">{{setTotalRealFee+'元'}}</span>
            <!--                            ￥{{initUpdateProgressData.totalRealFee}}</span>-->
          </div>
        </div>
        <div class="dividing-line"></div>
        <van-button :class="initUpdateProgressData.hardBeginTime&&initUpdateProgressData.hardEndTime&&initUpdateProgressData.softBeginTime&&initUpdateProgressData.softEndTime?'saveButton_TotalEnable':'saveButton_Disable'"
        :disabled="!(initUpdateProgressData.hardBeginTime&&initUpdateProgressData.hardEndTime&&initUpdateProgressData.softBeginTime&&initUpdateProgressData.softEndTime) || enable"  @click="saveUpdateProgressData">保存</van-button>
      </van-tab>
      <van-tab title="变更记录">
        <div class="firstTitle"></div>
                    <div class="stateList" v-for="(item,index) in alertRecord" :key="index" >
                        <!--<div :class="item.contractStatusVal=='租期中'?'top-title-green':item.contractStatusVal=='已解除'?'top-title-red':'top-title-orange'">{{item.contractStatusVal}}</div>
                        <div class="position">{{item.houseAddress}}</div>

                        <div class="contract-info" >
                            <div class="contract-text">申请人：{{item.addStaff_id }}</div>
                            <div class="contract-text">申请时间：{{getApplyTime(item.applyDate)}}</div>
                            <div class="contract-text">申请原因：{{item.changeapply_id}}</div>
                            <div class="contract-text">审批人：{{item.roomName}}</div>
                            <span class="contract-text">审批意见：{{item.name}}</span>
                            <span class="contract-text">审批时间：{{item.name}}</span>
                             <van-button class="saveButton_Enable" @click="checkAlertRecord(item.id)" style="float:right">变更记录</van-button>
                        </div>-->
              <div v-if="item.approvalStatusStr=='未审批'" class="stateUnapproval">
                {{ item.approvalStatusStr }}
              </div>
              <div v-if="item.approvalStatusStr=='已审批'" class="statePassed">
                {{ item.approvalStatusStr }}
              </div>
              <div v-if="item.approvalStatusStr=='已驳回'||item.approvalStatusStr=='审批拒绝'" class="stateRejected">
                {{ item.approvalStatusStr }}
              </div>
              <div class="headInfo">
                <!-- afterRentStatusStr 装修 方案 验收 决算-->
                <!--<span class="headSpan"> </span>
                <div class="dot"></div>
                {{getApplyTime(item.applyDate) }}-->
              </div>
              <div class="listDetail">
                <div class="proposerDiv">申请人</div>


                <div  class="budgetDiv">申请原因</div>

                <!--                    第二条竖线-->

                <div class="remouldDiv">审批人</div>
                <!--                    获取申请人的信息-->
                <div class="proposerValueDiv">{{ item.applyStaffName }}</div>
                <!--                    获取装修费用值的信息-->
                <div class="budgetValueDiv">{{ item.dictionaryTitle }}</div>
                <!--                    获取改造房屋的信息-->
                <div class="remouldValueDiv">{{ item.approvalApplicantStaff }}</div>
              </div>
              <div class="rule3"></div>
              <!--                循环列表下部分-->
              <div class="listFloor">
                <div  class="createMethodsDiv">申请时间 : </div>
                <div class="createTimeDiv">{{ getApplyTime(item.applyDate) }}</div>
                <!--审核按钮-->
                <div>
                  <button class="btn2"  @click="checkAlertRecord(item)">变更记录</button>
                </div>
              </div>
                        </div>
      </van-tab>
      <van-tab title="预算方案">
        <div class="header">
            <!-- 合同切换区域-->
            <div class="choose">
                <div class="choose-one">
                    <div :class="budgetType=='0'?'choose-one-textActive':'choose-one-text'" @click="checkBudgetData('0')">硬装</div>
                    <div class="choose-one-icon" v-show="budgetType=='0'"></div>
                </div>
                <div class="choose-line"></div>
                <div class="choose-one">
                    <div :class="budgetType=='1'?'choose-one-textActive':'choose-one-text'" @click="checkBudgetData('1')">软装</div>
                    <div class="choose-one-icon" v-show="budgetType=='1'"></div>
                </div>
                 <div class="choose-line"></div>
            </div>
        </div>
        <div class="contracts">
            <van-pull-refresh v-model="budgetRefreshing"  @refresh="onBudgetRefresh">
                <van-list
                        v-model="budgetLoading"
                        :finished="budgetFinished"
                        :finished-text="budgetNoContent ? '' : '已经到底了'"
                        @load="this.getHardBudgetProgramData"
                >
                    <!--合同列表-->
                    <div class="contract" v-for="(item,index) in budgetSchemeList" :key="index">
                        <div class="top-title-green">{{item.roomName?item.roomName:'公区'}}间</div>
                        <div class="position">{{item.middlesumName}}</div>
                        <div class="contract-info" >
                            <div class="contract-text">区域：{{item.regionalLocationName?item.regionalLocationName:'' }}</div>
                            <div class="contract-text">装修项：{{item.goodsallocationName }}</div>
                            <div class="contract-text">数量：{{item.totalCount}}</div>
                            <div class="contract-text">金额：{{item.estimateMoney}}</div>
                            <div class="contract-text">供应商：{{item.supplierName}}</div>
                            <div class="contract-text">加项原因：{{item.reasonAdditionTitle}}</div>
                             <div class="contract-textLong">备注：{{item.remarks}}</div>
                        </div>
                    </div>
                    <common-empty v-if="noContent"></common-empty>
                </van-list>
            </van-pull-refresh>
        </div>
      </van-tab>
      <van-tab title="账单">
        <van-row style="margin-top: 10px"></van-row>
        <van-pull-refresh v-model="isLoading" success-text="刷新成功" @refresh="onRefresh">
          <van-list
              v-model="loading"
              :finished="finished"
              :finished-text="noContent ? '' : '已经到底了'"
              @load="onLoad"
          >

					<div class="bill" v-if="billList.length>0">
						<div v-for="(item,i) in billList" :key="i">
							<div class="billPayTime">{{item.billFeeType_id == '2' && (item.billStatus == 2 || item.billStatus == 3) ? item.billEndTime : item.billPayTime}}</div>
							<div class="bill_panel">
								<van-row class="bill_info" type="flex" align="center">
									<!-- 四种状态 待确认billStatus 账单状态(0 应收=应支 1已收=已支 2 应付=应收 3 已付=已收 4 停付) 作为业主 对应的是海米  应该支付给海米（应支） 134 金额浅色-->
									<div class="laber " v-if="item.billStatus==0">
										<div class="laberText  ">应收</div>
									</div>
									<div class="laber " v-if="item.billStatus==1">
										<div class="laberText ">已收</div>
									</div>
									<div class="laber " v-if="item.billStatus==2">
										<div class="laberText ">应支</div>
									</div>
									<div class="laber " v-if="item.billStatus==3">
										<div class="laberText ">已支</div>
									</div>
									<div class="laber " v-if="item.billStatus==4">
										<div class="laberText ">停付</div>
									</div>
									<van-col span="19">
										<p>
											{{item.billFeeType}}
											<span>{{item.billDescription ? '(' + item.billDescription.substring(0,4) + ')' : ''}}</span>
										</p>
										<p class="small">
											<span>费用周期：</span>
											<span>{{item.billBeginTime}}</span>
											<span class="hasPadding">至</span>
											<span>{{item.billEndTime}}</span>
										</p>
									</van-col>

									<van-col span="6">
										<p :class="item.billStatus=='1' || item.billStatus=='3' || item.billStatus=='4' ? 'rentPriceA':' total_color bill_money'">
											{{item.totalAmount}}元
										</p>
									</van-col>
								</van-row>
							</div>
						</div>
					</div>
            <!--<div class="panelBill">
              <div v-for="body in billList" @click="checkBillDetails(body)" :key="body.id">
                <div style="width: 50%;float: left;padding: 16px 0px 3px 14px"><span
                    style="font-weight: 900;font-size: 15px">{{ body.billFeeType }}</span></div>
                <div style="text-align: right;padding: 16px 14px 3px 0px;position: relative;overflow: hidden">
              <span
                  style="font-weight: 900;font-size: 15px;color: #fe6641">{{
                  body.totalAmount > 0 ? '-' : ''
                }}{{ body.totalAmount }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" v-show="body.billDescription">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.billDescription }}</span>
                </div>
                <div style="width: 100%; float:left;padding: 0px 0px 3px 14px" class="bordBottom">
                  <span style="font-size: 10px;color: #9d9d9d">{{ body.addTime }}</span>
                </div>
              </div>
            </div>-->
          </van-list>
          <common-empty v-if="noContent"></common-empty>
        </van-pull-refresh>
      </van-tab>
    </van-tabs>
    <div>
      <div class="getPwd"></div>
    </div>
    <!--        项目编辑框弹出-->
    <van-popup v-model="isEdit" position="bottom" @click-overlay="actualCost = ''">
      <div style="background-color: #FAFAFA; overflow: hidden">
        <div class="popupTitle"><span>项目详情</span></div>
        <div class="container-projectCost ">
          <div class="detailLine addTop ">
                        <span class="font-bold ">
                            {{ projectItem }}<span class="font-normal"> X </span><span
                            class="redColor">{{ projectItemCount }}</span>
                        </span>
          </div>
          <div class="detailLine addBottom">
            <span class="font-bold ">预算：</span>
            <span class="font-normal ">{{ budget }}.00元</span>
          </div>
        </div>
        <!--<div style="display: flex;align-items: center">
          <div class="singleBox" style="display: flex;align-items: center">
            <span class="maker" :class="{'orangeColor':true}">• </span>
            <div class="blockTitle">实际费用</div>
            <div class="content-divide">|</div>
            <input type="number" class="inputPrice"
                   onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"
                   placeholder="输入金额"
                   v-model="actualCost">
            <div class="priceUnit">元</div>
          </div>
          <div style="margin-left: auto;display: flex;align-items: center" @click="sameBudget">
            <img class="samPic" :src="isSameAsExpected?orangeTickImg:grayTickImg">
            <div class="sameTip" :class="isSameAsExpected?'sameColor':'notSameColor'">与预算相同</div>
          </div>
        </div>-->
              <div class="part-inputpart-row" @click="showSupplier = !showSupplier">
                <span :class=" !supplier.id ? 'content-none' : 'content-have' "></span>
                <span class="font-bold ">供应商:</span>
                <span class="font-normal ">{{
                    supplier.name
                  }}</span>
              </div>
        <van-button class="saveProjectButton_Enable" @click="saveReceive()">保存</van-button>
      </div>
    </van-popup>
                        <van-popup v-model="showSupplier" position="bottom">
                          <van-picker
                            show-toolbar
                            value-key="supplierName"
                            :columns="supplierList"
                            @cancel="showSupplier = false"
                            @confirm="selectSupplierOption"
                          />
                       </van-popup>
        <van-overlay :show="softShow" @click="softShow = false">
            <div class="wrapper" @click.stop>
                <div class="block" >
                    <div class="getPassword">
                        确认完成吗？
                    </div>
                    <div class="LockPassWord">
                        <p class="password" @click="softFinish('1')">确认 </p>
                    </div>
                </div>
                <div class="closeImg" @click="softClose">
                </div>
            </div>
        </van-overlay>
  </div>
</template>

<script>
import Vue from 'vue'
import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
import {
  NavBar,
  Button,
  Tabs,
  Tab,
  Image as VanImage,
  ImagePreview,
  DatetimePicker,
  Collapse,
  CollapseItem,
  Uploader,
  Popup,
  Divider,
  Col,
  Row,
  List, PullRefresh,Overlay, Picker,Dialog
} from 'vant';
import {base64Upload, browseBillList, showProgramme, updateProgressInfo,updateProgressNew,querySupplierList,distributionitemReceiving,queryHardcoverSoftOutfit,querydistributionitemChange,querybudgetterm,renovationorderreceivecompleteCount,renovationorderreceivecomplete,updateProgressDecorationNew,addHardorSoftTime,addUpdatePictures} from "../../getData/getData";
import {getStaffId, globaluserId, responseUtil,checkAuthList} from "../../libs/rongxunUtil";

Vue.use(Collapse);
Vue.use(CollapseItem);
import BScroll from "better-scroll";

export default {
  name: 'updateProgress',

  components: {
    [NavBar.name]: NavBar,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [VanImage.name]: VanImage,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [DatetimePicker.name]: DatetimePicker,
    [Collapse.name]: Collapse,
    [CollapseItem.name]: CollapseItem,
    [Uploader.name]: Uploader,
    [Popup.name]: Popup,
    [Divider.name]: Divider,
    [Col.name]: Col,
    [Row.name]: Row,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
    rxNavBar,
    [Overlay.name]: Overlay,
    [Picker.name]: Picker,
    [Dialog.name]:Dialog,
  },
  watch: {
    /*'initUpdateProgressData.softBeginTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.softEndTime && this.initUpdateProgressData.softEndTime <= newVal){
        responseUtil.alertMsg(this,'开始时间不能晚于结束时间')
        this.initUpdateProgressData.softBeginTime = oldVal
      }
    },
    'initUpdateProgressData.softEndTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.softBeginTime && this.initUpdateProgressData.softBeginTime >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.initUpdateProgressData.softEndTime = oldVal
      }
    },
    'initUpdateProgressData.hardBeginTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.hardEndTime && this.initUpdateProgressData.hardEndTime <= newVal){
        responseUtil.alertMsg(this,'开始时间不能晚于结束时间')
        this.initUpdateProgressData.hardBeginTime = oldVal
      }
    },
    'initUpdateProgressData.hardEndTime'(newVal,oldVal){
      if(newVal != "" && this.initUpdateProgressData.hardBeginTime && this.initUpdateProgressData.hardBeginTime >= newVal){
        responseUtil.alertMsg(this,'结束时间不能早于于开始时间')
        this.initUpdateProgressData.hardEndTime = oldVal
      }
    }*/
  },
  data() {
    return {
      roleType:'1',   //登录人是否为装修负责人标识， 是 0   否 1
      isLoading: false,
      loadingFlag:false,//上传图片loading
      loading: false,
      finished: false,
      noContent: false,
      page: 1,
      billList: [],
      rtid: this.$route.query.rtid || '',
      hardDisabled: false,
      softDisabled: false,
      soBeginTime: '',
      soEndTime: '',
      typehs: '',
      currentDate: new Date(),
      //硬装起始日期
      ishcStartShow: false,
      //硬装截止日期
      ishcEndShow: false,
      //软装起始日期
      issoStartShow: false,
      //软装截止日期
      issoEndShow: false,
      index: '',
      info_Index: '',
      idx: '',
      info_idx: '',
      room_Index: '',
      initUpdateProgressData: {
        id: '',
        softchargeGlobaluser_id: '',
        softfeePrice: '',
        hardchargeGlobaluser_id: '',
        hardfeePrice: '',
        handInquest: '',
        softInquest: '',
        hardAppointmentBeginTime: '',
        hardAppointmentEndTime: '',
        softAppointmentBeginTime: '',
        softAppointmentEndTime: '',
        hardInpart: '',
        softInpart: '',
        firstHardfeePrice: '',
        firstSoftfeePrice: '',
        hardBeginTime: '',
        hardEndTime: '',
        hardRealFee: '',
        softBeginTime: '',
        softEndTime: '',
        softRealFee: '',
        budgetFee: '',
        totalRealFee: '',
        contractInfo: {},
        headList: {},
        imgList: [],
      },
      formUpdateProgressValidate: {
        id: '',
        doImgList: [],
        hardMapList: [],
        softMapList: [],
        roomList: [],
        finalSoftProgrammeMap: {},
        finalHandProgrammeMap: {},
        hardCheckMap: {},
        softCheckMap: {},
      },
      isEdit: false,
      projectItem: '',
      projectItemCount: '',
      budget: '',
      grassgreenTick: require('../../assets/images/GrassgreenTick.png'),
      orangeTickImg: require('../../assets/images/orangeTick.png'),
      grayTickImg: require('../../assets/images/grayTick.png'),
      actualCost: '',
      hard_arrActive: [],
      soft_arrActive: [],
      outerActive: 0,
      innerActive: 0,
      buildImg: [],
      buildNewImg:[],
      imgList: [],
      imgConstructList:[],
      hardShow:false,  //硬装弹出框
      softShow:false,  //软装弹出框
      showSupplier:false, //弹出供应商
      supplierList:[],    //供应商列表
      abc:[],
      ab:'',
      hardList:[],          //软装大项
      hardLittleList:[],   //硬装小项
      softList:[],         //软装大项
      softLittleList:[],   //软装小项
      supplier:{
          id:'',
          name:''
      },
      rowData:{}, //行数据
      alertRecord:[],   //变更记录
      budgetSchemeList:[],
      updateList:[],    //权限
      hardBudgetSchemeList:[],
      BudgetSchemeList:[],
      hardMiddleList:[],
      softMiddleList:[],
      sethardrealfeePrice:0,    //硬装实际费用
      setsoftfeePrice:0,        //软装实际费用
      setTotalRealFee:0,        //总实际费用
      budgetType:'0',            //预算方案类型
      budgetLoading:false,
      budgetFinished:false,
      budgetNoContent:false,
      budgetRefreshing:false,
      hardBudgetInitParam:{currentPage:1,numberPage:3},
      softBudgetInitParam:{currentPage:1,numberPage:3},
      saveingStatus:false,
      hardTotalFinish:false,
      softTotalFinish:false,
      editConstructImg:false,
      enable:false,
    }
  },
  filters: {
    formatDateTime(value) {
      if (null == value || '' == value) return
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      // return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
      return y + '-' + MM + '-' + d
    }
  },
  methods: {
    //开始接单、完成
    startOrder(e){
          let that=this
          let initData={}
          initData.itemType=e
          initData.rt_id=that.rtid
          console.log(initData)
          addHardorSoftTime(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                  console.log(response)
                  responseUtil.alertOK(that,'操作成功')
                  that.initRenovationData()

              })
          })
    },
    checkBillDetails(e){
      return
      this.$router.push({
        name:'checkBillDetails',
          query:{
            item:e
          }
      })
    },
            checkAuthList,
            //返回上一层
            leftReturn(){
                this.$router.go(-1);
            },
    onRefresh() {
      this.billList = [];
      setTimeout(()=>{
        this.page = 1;
        this.finished = false
        this.loading = true
        this.noContent = false
        this.isLoading = false
        this.onLoad();
      },500)

    },

    onLoad() {
      let that = this
      let initdata = {}
      //员工id
      if(that.roleType == '1'){
        initdata.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initdata.user_id = globaluserId()
      }
      initdata.currentPage = that.page
      initdata.numberPage = 10
      // //租后id 可不填
      initdata.afterRent_id = that.$route.query.arid
      //套房源id 可不填
      if (that.$route.query.setHouse_id)
        initdata.setHouse_id = that.$route.query.setHouse_id
      browseBillList(initdata).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response)
          let list = response.data.data.data
          if (list)
            that.billList = that.billList.concat(response.data.data.data)
          that.loading = false
          if (that.billList.length >= response.data.data.pageCount)
            that.finished = true
          if (1 == that.page && (!list || 0 == list.length))
            that.noContent = true
          else
            that.noContent = false
          that.page++;
        })
      })
    },

    //返回上一层
    leftReturn() {
      this.$router.go(-1);
    },
    setTotalRealFeee: function () {
      var hardfeePrice = 0
      for (let obj in this.formUpdateProgressValidate.hardCheckMap) {
        if (this.formUpdateProgressValidate.hardCheckMap[obj] && this.formUpdateProgressValidate.hardCheckMap[obj].checked) {
          var price = this.formUpdateProgressValidate.hardCheckMap[obj].realMoney ? this.formUpdateProgressValidate.hardCheckMap[obj].realMoney : '0'
          hardfeePrice = hardfeePrice + Number(price)
        }
      }
      var softfeePrice = 0
      for (let sobj in this.formUpdateProgressValidate.softCheckMap) {
        for (let ssubobj in this.formUpdateProgressValidate.softCheckMap[sobj]) {
          if (this.formUpdateProgressValidate.softCheckMap[sobj] && this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj] && this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].checked) {
            var prices = this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].realMoney ? this.formUpdateProgressValidate.softCheckMap[sobj][ssubobj].realMoney : '0'
            softfeePrice = softfeePrice + Number(prices)
          }
        }
      }
      this.initUpdateProgressData.totalRealFee = Number(hardfeePrice) + Number(softfeePrice)
      return this.initUpdateProgressData.totalRealFee
    },
    // 获取装修数据
    initRenovationData: function () {
      var that = this
      let queryData = {}
      if(that.roleType == '1'){
        queryData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        queryData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      queryData.rtid = that.rtid
      //  queryData.rtid = 64
      showProgramme(queryData).then(function (response) {
        responseUtil.dealResponse(that, response, () => {
          console.log(response.data.data)
          that.initUpdateProgressData.handInquest = response.data.data.headMap.hardAppointmentTimeLong
          that.initUpdateProgressData.softInquest = response.data.data.headMap.softAppointmentTimeLong

          that.initUpdateProgressData.softchargeGlobaluser_id = response.data.data.headMap.softChargeGlobaluser_id ? response.data.data.headMap.softChargeGlobaluser_id.toString() : ''
          that.initUpdateProgressData.softfeePrice = response.data.data.headMap.softBudgetFee
          that.initUpdateProgressData.hardchargeGlobaluser_id = response.data.data.headMap.hardChargeGlobaluser_id ? response.data.data.headMap.hardChargeGlobaluser_id.toString() : ''
          that.initUpdateProgressData.hardfeePrice = response.data.data.headMap.handBudgetFee

          that.initUpdateProgressData.hardChargeName = response.data.data.headMap.hardChargeName
          that.initUpdateProgressData.softChargeName = response.data.data.headMap.softChargeName

          that.initUpdateProgressData.hardAppointmentBeginTime = response.data.data.headMap.hardAppointmentBeginTime
          that.initUpdateProgressData.hardAppointmentEndTime = response.data.data.headMap.hardAppointmentEndTime
          that.initUpdateProgressData.softAppointmentBeginTime = response.data.data.headMap.softAppointmentBeginTime
          that.initUpdateProgressData.softAppointmentEndTime = response.data.data.headMap.softAppointmentEndTime
          that.initUpdateProgressData.hardBeginTime = response.data.data.headMap.hardBeginTime ? new Date(response.data.data.headMap.hardBeginTime) : ''
          that.initUpdateProgressData.hardEndTime = response.data.data.headMap.hardEndTime ? new Date(response.data.data.headMap.hardEndTime) : ''
          that.initUpdateProgressData.softBeginTime = response.data.data.headMap.softBeginTime ? new Date(response.data.data.headMap.softBeginTime) : ''
          that.initUpdateProgressData.softEndTime = response.data.data.headMap.softEndTime ? new Date(response.data.data.headMap.softEndTime) : ''
          that.initUpdateProgressData.hardRealFee = response.data.data.headMap.hardRealFee
          that.initUpdateProgressData.softRealFee = response.data.data.headMap.softRealFee

          that.initUpdateProgressData.pathList = response.data.data.pathList  //装修描述下面的设计图片pathList
          that.initUpdateProgressData.updatePictures = response.data.data.updatePictures  //之前上传的施工图片updatePictures
          console.log(that.initUpdateProgressData.updatePictures)
          if (response.data.data.updatePictures && response.data.data.updatePictures.length){
            for (var i = 0; i < that.initUpdateProgressData.updatePictures.length; i++) {
              that.imgConstructList.push(that.initUpdateProgressData.updatePictures[i].path)  //把施工图片放imgConstructList里,预览数组
            }
            // that.$nextTick(() => {
            //   if (!that.scroll) {
            //     that.scroll = new BScroll(that.$refs.wrapperBox_house, {
            //       scrollX: true,
            //       eventPassthrough: 'vertical'
            //     })
            //   }
            // })
          }




          if (response.data.data.pathList && response.data.data.pathList.length){
            for (var i = 0; i < that.initUpdateProgressData.pathList.length; i++) {
              that.imgList.push(that.initUpdateProgressData.pathList[i].path)  //把设计图片放imgList里
            }
            that.$nextTick(() => {
              if (!that.scroll) {
                that.scroll = new BScroll(that.$refs.wrapperBox_house, {
                  scrollX: true,
                  eventPassthrough: 'vertical'
                })
              }
            })
          }

          that.initUpdateProgressData.hardInpart = response.data.data.headMap.hardFirstPhaseProportion
          that.initUpdateProgressData.softInpart = response.data.data.headMap.softFirstPhaseProportion
          that.initUpdateProgressData.firstHardfeePrice = response.data.data.headMap.firstHardfee
          that.initUpdateProgressData.firstSoftfeePrice = response.data.data.headMap.firstSoftfee

          that.initUpdateProgressData.budgetFee = response.data.data.headMap.budgetFee
          that.initUpdateProgressData.totalRealFee = response.data.data.headMap.totalRealFee

          that.initUpdateProgressData.headList = response.data.data.headMap
          that.initUpdateProgressData.contractInfo = response.data.data.contractInfo

          that.formUpdateProgressValidate.doImgList = response.data.data.doImgList //改代码之前的 最后统一施工图片
          if (response.data.data.doImgList && response.data.data.doImgList.length){
            for (var j = 0; j < response.data.data.doImgList.length; j++) {
              that.buildImg.push({      //把施工图片放buildImg里
                content: response.data.data.doImgList[j].path,
                url: response.data.data.doImgList[j].path
              })
            }
            // that.$nextTick(() => {
            //   if (!that.scroll2) {
            //     that.scroll2 = new BScroll(that.$refs.wrapperBox_build, {
            //       scrollX: true,
            //       eventPassthrough: 'vertical'
            //     })
            //   }
            // })
          }
          that.formUpdateProgressValidate.hardMapList = response.data.data.allHardList
          that.formUpdateProgressValidate.softMapList = response.data.data.allSoftList
          //console.log(that.formUpdateProgressValidate.softMapList)
          that.formUpdateProgressValidate.roomList = response.data.data.roomList
          that.formUpdateProgressValidate.finalHandProgrammeMap = response.data.data.selectedHardMap//同一个sql
          that.formUpdateProgressValidate.finalSoftProgrammeMap = response.data.data.selectedSoftMap//同一个sql

          that.formUpdateProgressValidate.hardCheckMap = that.initHardCheckMap(that.formUpdateProgressValidate.finalHandProgrammeMap, that.formUpdateProgressValidate.hardMapList)
          that.formUpdateProgressValidate.softCheckMap = that.initSoftCheckMap(that.formUpdateProgressValidate.finalSoftProgrammeMap, that.formUpdateProgressValidate.roomList, that.formUpdateProgressValidate.softMapList)
          for(let i in that.formUpdateProgressValidate.hardMapList){
              if(that.formUpdateProgressValidate.hardMapList[i].check){
                  that.hardList.push(that.formUpdateProgressValidate.hardMapList[i])
              }
          }
          //console.log(that.hardList)
          for(let i in that.formUpdateProgressValidate.softMapList){
              if(that.formUpdateProgressValidate.softMapList[i].check){
                  that.softList.push(that.formUpdateProgressValidate.softMapList[i])
              }
          }
//         console.log(that.formUpdateProgressValidate.hardCheckMap)
          if (response.data.data.headMap) {
            that.hardDisabled = response.data.data.headMap.hardFinishTime ? true : false
            that.softDisabled = response.data.data.headMap.softFinishTime ? true : false
          }
        })
      })
    },

    editConstruct(){
      this.buildNewImg=[]
      this.editConstructImg = !this.editConstructImg
    },
    /**
     * 初始化chekbox的map
     * @param map
     */
    initHardCheckMap(map, list) {
      var result = {}
      for (let obj in list) {
        var subMap = list[obj] || {}
        var children = subMap['children'] || []
        var checkFlag = false
        for (var i = 0; i < children.length; i++) {
          var child = children[i] || {}
          if (child.id) {
            var map1 = {
              checked: map[child.id] ? true : false,
              id: map[child.id] && map[child.id].id ? map[child.id].id : '',
              count: map[child.id] && map[child.id].totalCount ? map[child.id].totalCount : 0,
              realMoney: map[child.id] && (map[child.id].realMoney || 0===map[child.id].realMoney) ? map[child.id].realMoney : (map[child.id] && (map[child.id].estimateMoney || 0===map[child.id].estimateMoney) ? map[child.id].estimateMoney : 0),
              money: map[child.id] && (map[child.id].estimateMoney || 0===map[child.id].estimateMoney) ? map[child.id].estimateMoney : 0,
            }
            result[child.id] = map1
            if (map[child.id] && !checkFlag) {
              checkFlag = true
            }
          }
        }
        subMap.check = checkFlag
      }
      return result
    },
    initSoftCheckMap(map, roomList, list) {

      var result = {}
      for (let obj in list) {
        var subMap = list[obj] || {}
        var children = subMap['children'] || []
        var checkAllFlag = false
        for (var i = 0; i < children.length; i++) {
          var child = children[i] || {}
          var roomList = roomList || []
          if (map[child.id]) {
            if (child.id) {
              result[child.id] = {}
            }
            var checkFlag = false
            for (var j = 0; j < roomList.length; j++) {
              var room = roomList[j] || {}
              if (room.id) {
                var map1 = {
                  checked: map[child.id][room.id] ? true : false,
                  id: map[child.id][room.id] && map[child.id][room.id].id ? map[child.id][room.id].id : '',
                  count: map[child.id][room.id] && map[child.id][room.id].totalCount ? map[child.id][room.id].totalCount : 0,
                  realMoney: map[child.id][room.id] && (map[child.id][room.id].realMoney || 0 === map[child.id][room.id].realMoney) ? map[child.id][room.id].realMoney : (map[child.id][room.id] && (map[child.id][room.id].estimateMoney || 0 === map[child.id][room.id].estimateMoney) ? map[child.id][room.id].estimateMoney : 0),
                  money: map[child.id][room.id] && (map[child.id][room.id].estimateMoney || 0 === map[child.id][room.id].estimateMoney) ? map[child.id][room.id].estimateMoney : 0,
                }
                result[child.id][room.id] = map1
                if (map[child.id][room.id] && !checkFlag) {
                  checkFlag = true
                }
              }
            }
            result[child.id].check = checkFlag
            if (checkFlag && !checkAllFlag) {
              checkAllFlag = true
            }
          }
        }
        subMap.check = checkAllFlag
      }
      return result
    },
    //与预算相同
    sameBudget() {
      if (this.typehs == 1) {
        this.actualCost = Number(this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[this.index].children[this.info_Index].id].money)
      } else {
        var room = this.formUpdateProgressValidate.roomList[this.room_Index];
        this.actualCost = Number(this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[this.idx].children[this.info_idx].id][room.id].money)
      }
    },
    //保存当前实际费用
    savePrice() {
      this.isEdit = false;
      if (this.typehs == 1) {
        this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[this.index].children[this.info_Index].id].realMoney = Number(this.actualCost)
        this.actualCost = ''
      } else {
        var room = this.formUpdateProgressValidate.roomList[this.room_Index];
        this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[this.idx].children[this.info_idx].id][room.id].realMoney = Number(this.actualCost)
        this.actualCost = ''
      }
      this.setTotalRealFeee();
    },
    editProjecthc(index, info_Index) {
      this.index = index;
      this.info_Index = info_Index;
      this.projectItem = this.formUpdateProgressValidate.hardMapList[index].children[info_Index].itemName;
      this.projectItemCount = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].count;
      this.budget = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].money
      this.actualCost = this.formUpdateProgressValidate.hardCheckMap[this.formUpdateProgressValidate.hardMapList[index].children[info_Index].id].realMoney
      this.isEdit = true;
      this.typehs = 1;
    },
    editProjectso(idx, info_idx, room_Index) {
      this.idx = idx;
      this.info_idx = info_idx;
      this.room_Index = room_Index;
      let item = this.formUpdateProgressValidate.softMapList;
      var room = this.formUpdateProgressValidate.roomList[room_Index];
      this.projectItem = item[idx].children[info_idx].itemName + room.roomName
      this.projectItemCount = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].count;
      this.budget = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].money
      this.actualCost = this.formUpdateProgressValidate.softCheckMap[this.formUpdateProgressValidate.softMapList[idx].children[info_idx].id][room.id].realMoney
      this.isEdit = true;
      this.typehs = 2;
    },
    //修改供应商
    editSupplier(e) {
      if(!this.initUpdateProgressData.softBeginTime){
          responseUtil.alertMsg(this,'请选择实际开始日期')
          return
      }
      console.log(e)
      this.rowData=e
      this.projectItem=e.itemName
      this.projectItemCount=e.totalCount
      this.budget=e.estimateMoney
      this.supplier.id=e.supplier_id
      this.supplier.name=e.newsupplierName
        this.isEdit=true
    },


    //物品配置图片多选上传
    afterReadTest(file){
      // this.loadingFlag = true;
      let fileLength = parseInt(file.length) - parseInt(1)
      if(file.length > 1){
        for(let i = 0; i<file.length;i++){
          let fileDetail = file[i];
          this.afterRead(fileDetail,i,fileLength);
        }
      }else{
        this.afterRead(file);
      }
    },

    afterRead(file,i,fileLength) {
      let that = this
      let initData = {
        base64 : file.content
      }
      that.loadingFlag = true;
      base64Upload(initData).then(function (response) {
        that.loadingFlag = false;
        responseUtil.dealResponse(that, response, () => {
          file.url = response.data.data.path
          file.path = response.data.data.path
          if(i == fileLength){
            that.loadingFlag = false
          }
        })
      })
    },

    onClickLeft() {
    },
    goodsListClick(index) {
      let hash = this.hard_arrActive.findIndex(item => {
        return item == index
      })
      if (hash > -1) {
        this.hard_arrActive.splice(hash, 1)
      } else {
        this.hard_arrActive.push(index)
      }
    },
    soft_goodsListClick(index) {
      let hash = this.soft_arrActive.findIndex(item => {
        return item == index
      })
      if (hash > -1) {
        this.soft_arrActive.splice(hash, 1)
      } else {
        this.soft_arrActive.push(index)
      }
    },
    previewImg(start) {
      console.log(this.img)
      ImagePreview(
          {
            images: this.imgList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    previewConstructImg(start){
      console.log(this.initUpdateProgressData.updatePictures)
      ImagePreview(
          {
            images: this.imgConstructList,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    previewBuildImg(start) {
      console.log(this.buildImg)
      ImagePreview(
          {
            images: this.buildImg,
            startPosition: start,
            onClose() {
              // do something
            },
          }
      )
    },
    clickStarthcShow() {
      this.ishcStartShow = !this.ishcStartShow;
      // var panel = document.getElementById('starthcPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.ishcStartShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    starthcConfirm(value) {
      var date = new Date(value)
      this.initUpdateProgressData.hardBeginTime = date
      this.ishcStartShow = false
    },
    //日期选择组件 点击取消键后执行方法
    starthcCancel() {
      this.ishcStartShow = false
    },
    clickEndhcShow() {
      this.ishcEndShow = !this.ishcEndShow;
      // var panel = document.getElementById('endhcPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.ishcEndShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    endhcConfirm(value) {
      var date = new Date(value)
      this.initUpdateProgressData.hardEndTime = date
      this.ishcEndShow = false
    },
    //日期选择组件 点击取消键后执行方法
    endhcCancel() {
      this.ishcEndShow = false
    },
    //软装日期
    clickStartsoShow() {
      this.issoStartShow = !this.issoStartShow;
      // var panel = document.getElementById('startsoPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.issoStartShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    startsoConfirm(value) {
      var date = new Date(value)
      this.soBeginTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      this.initUpdateProgressData.softBeginTime = date
      this.issoStartShow = false
    },
    //日期选择组件 点击取消键后执行方法
    startsoCancel() {
      this.issoStartShow = false
    },
    clickEndsoShow() {
      this.issoEndShow = !this.issoEndShow;
      // var panel = document.getElementById('endsoPanel')
      // if (panel) {
      //   document.addEventListener('click', e => {
      //     if (!panel.contains(e.target)) {
      //       this.issoEndShow = false
      //     }
      //   })
      // }
    },
    //日期选择组件 点击确认键后执行方法
    endsoConfirm(value) {
      var date = new Date(value)
      this.soEndTime = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
      this.initUpdateProgressData.softEndTime = date
      this.issoEndShow = false
    },
    //日期选择组件 点击取消键后执行方法
    endsoCancel() {
      this.issoEndShow = false
    },
    //装修数据保存
    saveUpdateProgressData: function () {
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //  initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '2', '3']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      console.log(initData)
      that.saveUpdateProgress(initData, false, '')
    },
    closeHard(){
      this.hardShow=false
    },
    toHeardFinish(){
      this.hardShow=true
    },
    //硬装完成
    heardFinish: function () {
      if (!this.initUpdateProgressData.hardBeginTime || this.initUpdateProgressData.hardBeginTime == '') {
        responseUtil.alertMsg(this, '开始时间不能为空！')
        return
      }
      if (!this.initUpdateProgressData.hardEndTime || this.initUpdateProgressData.hardEndTime == '') {
        responseUtil.alertMsg(this, '结束时间不能为空！')
        return
      }
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '2']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      //  initData.user_id = 16
      that.saveUpdateProgress(initData, true, 'hard')
    },
    softClose(){
      this.softShow=false
    },
    toSoftFinish(){
      this.softShow=true
    },
    //软装完成
    /*softFinish: function () {
      if (!this.initUpdateProgressData.softBeginTime || this.initUpdateProgressData.softBeginTime == '') {
        responseUtil.alertMsg(this, '开始时间不能为空！')
        return
      }
      if (!this.initUpdateProgressData.softEndTime || this.initUpdateProgressData.softEndTime == '') {
        responseUtil.alertMsg(this, '结束时间不能为空！')
        return
      }
      var that = this
      let initData = this.initUpdateProgressData
      initData.id = that.rtid
      //initData.id = 64
      if (that.buildImg.length) {
        initData.imgList = []
        for (let i = 0; i < that.buildImg.length; i++)
          initData.imgList.push({path: that.buildImg[i].url})
      }
      initData.hardCheckMap = that.formUpdateProgressValidate.hardCheckMap
      initData.softCheckMap = that.formUpdateProgressValidate.softCheckMap
      initData.typeList = ['0', '1', '3']
      if(that.roleType == '1'){
        initData.user_id = getStaffId()
      }else if(that.roleType == '0'){
        initData.user_id = globaluserId()  //如果登录人是装修负责人（非员工），则使用其用户id
      }
      that.saveUpdateProgress(initData, true, 'soft')
    },*/
    //保存接口
    saveUpdateProgress: function (initData, flag, type) {
      var that = this
      that.enable=true
      updateProgressDecorationNew(initData).then(function (response) {
        that.enable=false
        responseUtil.dealResponse(that, response, () => {
          if(200 == response.status){
            responseUtil.alertOK(that, '操作成功')
            if (type == 'hard') {
              that.hardDisabled = true
            } else if (type == 'soft') {
              that.softDisabled = true
            }else{
              that.$router.go(-1)
            }
          }

          // that.initData()
          //that.$router.go(-1);
        })
      })
    },
      //分页查询硬装，软装数据
      getHardSoftPagingData(){
          let that=this
          let initData={}
          initData.user_id = getStaffId()
          initData.rtid = that.rtid
          //initData.hardCurrentPage=1
          //initData.hardPageSize=1000
          //initData.softCurrentPage=1
          //initData.softPageSize=1000
          that.sethardrealfeePrice=0
          that.setsoftfeePrice=0
          queryHardcoverSoftOutfit(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                  that.hardLittleList=response.data.data.hardMapList
                  console.log(that.hardLittleList)
                  if((that.hardLittleList.length>0&&that.hardLittleList[0].hardFinishTime)||that.hardLittleList.length==0){
                      that.hardTotalFinish=true
                  }
                  that.hardMiddleList=that.getMiddleData(that.hardLittleList)
                  console.log(that.hardMiddleList)
                  that.softLittleList=response.data.data.softMapList
                  if((that.softLittleList.length>0&&that.softLittleList[0].softFinishTime)||that.softLittleList.length==0){
                      that.softTotalFinish=true
                  }
                  that.softMiddleList=that.getMiddleData(that.softLittleList)
                  for(let i in that.hardLittleList){
                      that.sethardrealfeePrice+=that.hardLittleList[i].realMoney

                  }
                  for(let i in that.softLittleList){
                        if(that.softLittleList[i].status=='1'||that.softLittleList[i].status=='2'){
                            that.setsoftfeePrice+=that.softLittleList[i].realMoney
                        }

                  }
                  for(let i in that.hardMiddleList){
                      that.abc.push(that.hardMiddleList[i].middlesumName)
                  }
                  for(let i in that.softMiddleList){
                      that.abc.push(that.softMiddleList[i].middlesumName)
                  }
                  that.setTotalRealFee=that.setsoftfeePrice+that.sethardrealfeePrice
        })
      })
      },
      getMiddleData(arr){
          let obj = {};
          arr = arr.reduce((newArr, next) => {
            obj[next.goodsallocationmiddlesumId] ? "" : (obj[next.goodsallocationmiddlesumId] = true && newArr.push(next));
            return newArr;
          }, []);
          return arr;


      },
      //接单保存
      saveReceive(){
        let that=this
        let data={}
        data.afterRent_id=that.$route.query.arid
        data.renovation_id=that.$route.query.rtid
        data.user_id=getStaffId()
        data.softList=[]
        var json={}
        json.money=that.rowData.realMoney?that.rowData.realMoney:that.rowData.estimateMoney
        json.renovationitem_id=that.rowData.id
        json.supplier_id=that.supplier.id
        data.softList.push(json)
        console.log(data)
        that.saveingStatus=true
                  distributionitemReceiving(data).then(function (response) {
                      that.saveingStatus=false
                      responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertOK(that,'操作成功')
                        that.getHardSoftPagingData()
                        that.isEdit=false
                      })
                  })
      },
      //硬装完成弹出
      hardComplete(e){
          if(!this.initUpdateProgressData.hardBeginTime){
              responseUtil.alertMsg(this,'没有实际开始日期')
              return
          }
          this.rowData=e
          //this.hardShow=true
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定完成吗？',
                }).then(() => {
                    this.softFinish('0')
                })
                .catch(() => {
                });
      },
      //软装完成弹出
      softComplete(e){
          if(!this.initUpdateProgressData.softBeginTime){
              responseUtil.alertMsg(this,'没有实际开始日期')
              return
          }
          this.rowData=e
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定完成吗？',
                }).then(() => {
                    this.softFinish('1')
                })
                .catch(() => {
                });
        //this.softShow=true
      },
      softFinish(e){
                  var that = this
                  that.enable=true
                  let data = {}
                  data.itemType=e
                  let json={renovationdistributionitem_id:that.rowData.id}
                  data.renovation_id = this.$route.query.rtid
                  data.renovationdistributionitemList=[]
                  data.renovationdistributionitemList.push(json)
                  that.saveingStatus=true
                  data.staff_id=getStaffId()
        // return
                  renovationorderreceivecomplete(data).then(function (response) {
                      that.saveingStatus=false
                      that.enable=false
                      responseUtil.dealResponse(that, response, () => {
                          responseUtil.alertOK(that,'操作成功')
                            that.softShow=false
                            that.hardShow=false
                            that.getHardSoftPagingData()
                      })
                  })
      },
        //变更申请
        changeApply(e){
          console.log(e)
          console.log(this.innerActive)
            if(e=='0'&&!this.initUpdateProgressData.hardBeginTime){
                responseUtil.alertMsg(this,'请选择实际开始日期')
                return
            }else if(e=='1'&&!this.initUpdateProgressData.softBeginTime){
                responseUtil.alertMsg(this,'请选择实际开始日期')
                return
            }
            let data=[]
            if(e=='0'){
                for(let i in this.hardList){
                  let json={id:this.hardList[i].id,name:this.hardList[i].name}
                  data.push(json)
                }
            }else if(e=='1'){
                for(let i in this.softList){
                  let json={id:this.softList[i].id,name:this.softList[i].name}
                  data.push(json)
                }
                }
                this.$router.push({
                        name:'changeApplication',
                        query:{
                            itemType:e,
                            //program:data,
                            //roomList: this.formUpdateProgressValidate.roomList,
                            rtid:this.rtid,
                            arid:this.$route.query.arid,
                            setHouse_id:this.$route.query.setHouse_id
                        }
                })
        },
        //硬装软装总完成
        finishAllHardSoft(e){
            if(e=='0'&&!this.initUpdateProgressData.hardBeginTime){
                responseUtil.alertMsg(this,'请选择实际开始日期')
                return
            }else if(e=='1'&&!this.initUpdateProgressData.softBeginTime){
                responseUtil.alertMsg(this,'请选择实际开始日期')
                return
            }
          let that=this
          that.enable=true
          let initData={}
          initData.afterRent_id  =that.$route.query.arid
          initData.renovation_id =that.rtid
          initData.itemType =e
          initData.staff_id = getStaffId()
                renovationorderreceivecompleteCount(initData).then(function (response) {
                  that.enable=false
                  responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertOK(that,'操作成功')
                        that.initRenovationData()
                        that.getHardSoftPagingData()
                    })
                })
        },
        selectSupplierOption(e){
           this.supplier.id=e.id
           this.supplier.name=e.supplierName
           this.showSupplier=false
        },
        //供应商列表
        getQuerySupplierList(){
            let that=this
            let initData={}
            querySupplierList(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
                that.supplierList=response.data.data.data
            })
            })
        },
        //变更记录
        getAlertRecord(){
            var that = this
            let initData = {}
            initData.currentPage=1
            initData.numberPage=1000
            initData.afterRent_id=that.$route.query.arid
            initData.renovation_id=that.$route.query.rtid
            querydistributionitemChange(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                    that.alertRecord = response.data.data.data
                })
            })
        } ,
        //变更记录详情
        checkAlertRecord(item){
          console.log(item)
            this.$router.push({
                name:'changeApplicationDetails',
                query:{
                    //rtid:this.rtid,
                    //arid:this.$route.query.arid
                    id:item.id,
                    applyStaffName:item.applyStaffName,
                    dictionaryTitle:item.dictionaryTitle,
                    applyDate:this.getApplyTime(item.applyDate),
                    applyMemo:item.applyMemo,
                    approvalApplicantStaff:item.approvalStaffName,
                    approvalOpinion:item.approvalOpinion,
                    approvalStatusStr:item.approvalStatusStr,
                    approvalTime:item.approvalTime?this.getApplyTime(item.approvalTime):''

                }
            })
        },
        //将时间戳转为yyyy-MM-dd,申请时间
        getApplyTime(e){
            var date=new Date(e)
            return date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+' '+date.getHours()+':'+date.getMinutes()
        },
        //预算项列表
        getHardBudgetProgramData(){
          var that = this
          let initData = {}
          initData.currentPage= this.softBudgetInitParam.currentPage
          initData.numberPage=this.softBudgetInitParam.numberPage
          initData.afterRent_id=that.$route.query.arid
          initData.hardCurrentPage=this.hardBudgetInitParam.currentPage
          initData.hardNumberPage=this.hardBudgetInitParam.numberPage
          querybudgetterm(initData).then(function (response) {
              responseUtil.dealResponse(that, response, () => {
                /*if(response.data.data.hardData){
                  that.hardBudgetSchemeList=response.data.data.hardData
                }
                if(response.data.data.softData){
                  that.softBudgetSchemeList=response.data.data.softData
                }
                  that.budgetSchemeList=that.hardBudgetSchemeList.concat(that.softBudgetSchemeList)*/
                  let data=''
                  if(that.budgetType=='0'){
                      data=response.data.data.hardData
                  }else if(that.budgetType=='1'){
                      data=response.data.data.softData
                  }
                  if(data){
                      for(let i=0;i<data.length;i++){
                                that.budgetSchemeList.push(data[i])
                      }
                  }
                  that.budgetLoading=false
                  if(that.budgetType=='0'){
                    if (that.budgetSchemeList.length >=response.data.data.hardCount) {
                      that.budgetFinished = true;
                    }
                  }else if(that.budgetType=='1'){
                    if (that.budgetSchemeList.length >=response.data.data.softCount) {
                      that.budgetFinished = true;
                    }
                  }
                  that.softBudgetInitParam.currentPage++
                  that.hardBudgetInitParam.currentPage++

              })
          })
        },
        checkBudgetData(e){
                this.budgetType=e
                this.budgetSchemeList=[]
                this.budgetLoading=true
                this.budgetFinished=false
                this.budgetNoContent = false
                this.softBudgetInitParam.currentPage=1
                this.hardBudgetInitParam.currentPage=1
                this.getHardBudgetProgramData()
        },
        onBudgetRefresh(){
                // 清空列表数据
                this.budgetFinished = false;
                this.budgetSchemeList = []
                this.softBudgetInitParam.currentPage = 1
                this.hardBudgetInitParam.currentPage=1
                // 重新加载数据
                // 将 loading 设置为 true，表示处于加载状态
                this.budgetLoading = true;
                this.getHardBudgetProgramData();
                responseUtil.alertMsg(this,"刷新成功")
        } ,
        //切换
        onClickTab(e){
            console.log(e)
        },
        //保存施工图片
        saveConstructImg(){
          var that = this
          let initData = {}
          initData.addStaff_id = globaluserId()
          initData.pathList = that.buildNewImg
          initData.afterRentId = that.$route.query.arid
          initData.menuName = 'updateProgress'
          addUpdatePictures(initData).then(function (response) {
            responseUtil.dealResponse(that, response, () => {
              that.initRenovationData()
              that.editConstructImg = !that.editConstructImg
            })
          })
        },

        deletePictures(){

        }
  },
  computed:
      {
        //硬装实际合计费用
        /*sethardrealfeePrice() {
          var hardfeePrice = 0
          for (let obj in this.formUpdateProgressValidate.hardCheckMap) {
            if (this.formUpdateProgressValidate.hardCheckMap[obj] && this.formUpdateProgressValidate.hardCheckMap[obj].checked) {
              var price = this.formUpdateProgressValidate.hardCheckMap[obj].realMoney ? this.formUpdateProgressValidate.hardCheckMap[obj].realMoney : '0'
              hardfeePrice = hardfeePrice + Number(price)
            }
          }
          this.initUpdateProgressData.hardRealFeePrice = hardfeePrice
          return this.initUpdateProgressData.hardRealFeePrice
        },
        //软装预合计费用
        setsoftfeePrice() {
          var softfeePrice = 0
          for (let obj in this.formUpdateProgressValidate.softCheckMap) {
            for (let subobj in this.formUpdateProgressValidate.softCheckMap[obj]) {
              if (this.formUpdateProgressValidate.softCheckMap[obj] && this.formUpdateProgressValidate.softCheckMap[obj][subobj] && this.formUpdateProgressValidate.softCheckMap[obj][subobj].checked) {
                var price = this.formUpdateProgressValidate.softCheckMap[obj][subobj].realMoney ? this.formUpdateProgressValidate.softCheckMap[obj][subobj].realMoney : '0'
                softfeePrice = softfeePrice + Number(price)
              }
            }
          }
          this.initUpdateProgressData.softRealFeePrice = softfeePrice
          return this.initUpdateProgressData.softRealFeePrice
        },*/
        //总钱数
        /*setTotalRealFee() {
          var hardfeePrice = this.initUpdateProgressData.hardRealFeePrice ? this.initUpdateProgressData.hardRealFeePrice : 0
          var softfeePrice = this.initUpdateProgressData.softRealFeePrice ? this.initUpdateProgressData.softRealFeePrice : 0
          this.initUpdateProgressData.totalRealFee = Number(hardfeePrice) + Number(softfeePrice)
          return this.initUpdateProgressData.totalRealFee
        },*/

        isSameAsExpected() {
          if (this.budget == this.actualCost) {
            return true;
          }
          return false;
        },
        costFormat() {
          return function (value) {
            if (value >= 0) {
              return value + '.00元'
            }
            return '未装配';
          }
        },
        //硬装显示数量有0的每项前为红色
        inCpmpleteArrhc() {
          return function () {
            let countArr;
            let countAllArr;
            let redColorArr = [];
            // let  item = this.formUpdateProgressValidate.hardMapList;
            countArr = this.countConfigedhc();
            countAllArr = this.countAllhc();
            for (let t = 0; t < countArr.length; t++) {
              if (countArr[t] < countAllArr[t]) {
                redColorArr.push('red');
              } else {
                redColorArr.push('black')
              }
            }
            return redColorArr;
          }
        },
        //软装显示数量有0的每项前为红色
        inCpmpleteArrso() {
          return function () {
            let countArr;
            let countAllArr;
            let redColorArr = [];
            // let  item = this.formUpdateProgressValidate.softMapList;
            countArr = this.countConfigedso();
            countAllArr = this.countAllso();
            for (let t = 0; t < countArr.length; t++) {
              if (countArr[t] < countAllArr[t]) {
                redColorArr.push('red');
              } else {
                redColorArr.push('black')
              }
            }
            return redColorArr;
          }
        },
        //硬装显示有多少项有值的
        countConfigedhc() {
          return function () {
            let item = this.formUpdateProgressValidate.hardMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                if (typeof (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney) == "number") {
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney > 0) {
                    countTemp = countTemp + 1;
                  }
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].realMoney <= 0) {
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //软装显示有多少项有值的
        countConfigedso() {
          return function () {
            let item = this.formUpdateProgressValidate.softMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                for (var k = 0; k < this.formUpdateProgressValidate.roomList.length; k++) {
                  var room = this.formUpdateProgressValidate.roomList[k] || {}
                  if (room.id && this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id]) {
                    if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id] && typeof (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney) == "number") {
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney > 0) {
                        countTemp = countTemp + 1;
                      }
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].realMoney <= 0) {
                      }
                    }
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //硬装显示总数值
        countAllhc() {
          return function () {
            let item = this.formUpdateProgressValidate.hardMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                if (typeof (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count) == "number") {
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count > 0) {
                    countTemp = countTemp + 1;
                  }
                  if (this.formUpdateProgressValidate.hardCheckMap[item[j].children[i].id].count <= 0) {
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        //软装显示总数值
        countAllso() {
          return function () {
            let item = this.formUpdateProgressValidate.softMapList;
            let len = item.length
            let countTemp = 0;
            let countArr = [];
            for (let j = 0; j < len; j++) {
              countTemp = 0;
              let infolen = item[j].children.length
              for (let i = 0; i < infolen; i++) {
                for (var k = 0; k < this.formUpdateProgressValidate.roomList.length; k++) {
                  var room = this.formUpdateProgressValidate.roomList[k] || {}
                  if (room.id && this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id]) {
                    if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id] && typeof (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count) == "number") {
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count > 0) {
                        countTemp = countTemp + 1;
                      }
                      if (this.formUpdateProgressValidate.softCheckMap[item[j].children[i].id][room.id].count <= 0) {
                      }
                    }
                  }
                }
              }
              countArr[j] = countTemp;
            }

            return countArr;
          }
        },
        buttonEnable() {
          return (this.name.length > 0 && this.card_id.toString().length > 0 && this.bankOfDeposit.length > 0)
        },
        infoDisplayArr() {
          var displayArr = new Array(this.goodInfo.length);
          displayArr.fill(false);
          return displayArr;
        },
      },
  created() {
    //登录人是否为装修负责人标识， 是 0   否 1
    if(this.$route.query.roleType){
      this.roleType = this.$route.query.roleType;
    }
    this.updateList=this.$route.query.updateList
    // this.initBrowseBillList()
    this.initRenovationData()
    // this.$nextTick(() => {
    //   if (!this.scroll) {
    //     this.scroll = new BScroll(this.$refs.wrapperBox_house, {
    //       scrollX: true,
    //       eventPassthrough: 'vertical'
    //     })
    //     this.scroll = new BScroll(this.$refs.wrapperBox_build, {
    //       scrollX: true,
    //       eventPassthrough: 'vertical'
    //     })
    //
    //   }
    // })
    this.getHardSoftPagingData()
    this.getQuerySupplierList()
    this.getAlertRecord()    //变更记录
    //this.getHardBudgetProgramData()    //预算方案
  },
  mounted() {
    // countConfiged();
    // this.countConfiged();

  }

}
</script>


<style lang="less" scoped>
@outtab_textPadding: 13px;
@inertab_textPadding: 15px;
@text-padding-right: 48px;
@container_width: 8.8rem;
 @redius:8px;
.panelBill {
  width: 100%;
  margin: 0px 0px;
  background-color: rgb(255, 255, 255);
  position: relative;
  font-size: 15px;
  text-align: left;
  overflow: hidden;
}

.bordBottom {
  border-bottom: 1px solid rgb(244, 244, 244);
}


.tickInPopUp {
  padding-right: 2px;
  padding-bottom: 2px;
  width: 15px;
  line-height: 34px;
}

.getPwdImg {
  //margin-top: 10px;
  margin-left: 22px;
  margin-top: 17px;
  float: left;
  width: 20px;
}

.getPwd {
  font-size: 14px;
  float: left;
  margin-top: 23px;
  margin-left: 4px;
  margin-bottom: 10px;
}

.container-cost {
  background-color: white;
  width: @container_width + 0.4;
  height: auto;
  margin: 0px auto 50px;
  border-radius: 10px;
  //padding: 17px 0px;
}

.container-projectCost {
  background-color: white;
  width: @container_width + 0.4;
  height: auto;
  margin: 0px auto 18px;
  border-radius: 10px;
  //padding: 17px 0px;
}

.costLine {
  &.addTop {
    padding-top: 17px;
  }

  &.addBottom {
    padding-bottom: 14px;
  }

  display: flex;
  align-items: center;
  margin-left: auto;
  font-weight: 900;
  padding: 0px 20px;
  height: 23px;
}

.detailLine {
  &.addTop {
    padding-top: 12px;
  }

  &.addBottom {
    padding-bottom: 15px;
  }

  font-weight: 900;
  padding-left: 13px;
  //height: 25px;
  line-height: 16px;
}

.sumLine {
  color: rgb(244, 99, 76);
  font-weight: 900;
  padding: 0px 20px;
  height: 47px;
  line-height: 47px;
}

.arrowBox {
  position: absolute;
  right: 15px;
  top: 4px;
  margin-right: 5px;
}

.upArrowButton {
  width: 9px;
  transform: rotate(-90deg);
}

.downArrowButton {
  width: 9px;
  transform: rotate(90deg);
}

.editImg {
  width: 15px;
  display: flex;
}

.costStyle {
  text-align: center;
  //float: right;
  //padding-right: 15px;
}

.notSameColor {
  color: #989898;
}

.sameColor {
  color: #E05B42
}

.bluecolor {
  color: #3B98FF;
}

.goodTitle {
  padding-left: 15px;
  float: left;
  color: rgb(244, 99, 76);
  font-weight: 900;
}

.goodsOfConfig {
  color: black;
  margin-right: 65px;
  float: right;
  font-size: 16px;
  font-weight: 900;
}

.goodInfo-titleLine {
  font-size: 9px;
  background-color: #F4F9FF;
  height: 18px;
  padding-bottom: 15px;
  color: #a7adb3;
}

.innerTabs {
  position: relative;
  width: @container_width + 0.4;
  margin: 0 auto 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  position: relative;
  font-size: 15px;
  text-align: left;
  //overflow: hidden;
}

.tickletf {
  position: absolute;
  width: 15px;
  top: 15px;
  left: 122px;
  z-index: 2;
}

.tickRight {
  position: absolute;
  width: 15px;
  top: 15px;
  right: 29px;
  z-index: 2;
}

.dividing-line {
  //display: inline-block;
  height: 1px;
  width: 100%;
  margin: 0.1px 0;
  background-color: #e8e3e3;
}

// 图片墙
.swiper_show {

  width: @container_width;
  position: relative;
  margin: 8px 13px;
  height: 80px;

  .content {
    position: absolute;
    display: flex;
    justify-content: space-around;
    left: 0px;

    .imgWrap {

      margin-right: 10px;
    }
  }

  .num {
    position: absolute;
    left: 8px;
    top: 12px;
    font-size: 10px;
    background-color: rgb(0, 0, 0);
    opacity: 0.2;
    color: rgb(255, 255, 255);
    padding: 2px 6px;
    display: inline-block;
    border-radius: 10px;
    font-weight: 900;
    line-height: 10px;
  }

}

.uploadWrap {

  //: 5px;
  //font-size: 14px;
}

.detailInfo {
  //position: relative;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  padding-left: @outtab_textPadding;
}

.detailInfo-inner {
  margin-top: 15px;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 9.2rem;
  // padding-left: @inertab_textPadding;
}

.add-Paddding-Right {
  padding-right: @text-padding-right;
}

.add-midPaddding-Right {
  padding-right: 75px;
}

.add-morePaddding-Right {
  padding-right: 102px;
}

.twoCol {
  position: relative;
}

.addLineHigh {
  height: 23px;
  line-height: 23px;
}

.addSmallineHigh {
  padding-left: 15px;
  height: 25px;
  line-height: 25px;
}

.littlelineHigh {
  padding-left: 15px;
  height: 22px;
  line-height: 22px;
}

.add-pos {
  position: absolute;
  left: 160px;
}

.addLineHigh-auto {
  height: auto;
  line-height: 22px;
}

.float-Left {
  float: left;
}

.float-Right {
  float: right;
}

.kd {
  width: 90px;
}

.font-bold {
  font-size: 14px;
  font-weight: bold;
}

.font-normal {
  font-size: 14px;
  font-weight: normal;
}

.costTitle {
  font-size: 14px;
  font-weight: bold;
  padding-left: 15px;
}

.container-line {
  position: relative;
  // overflow: hidden;
  background-color: white;
  width: @container_width;
  margin: 0px auto 10px auto;
  border-radius: 0px;
  height: 55px;
  line-height: 55px;

}

.goodInfoTable {
  text-align: center;
  border: none;
  border-spacing: 0px;
  /*width: 9.3rem;*/
  padding: 0px 0px 10px;

  tr:not(:first-child) {
    font-size: 15px;
    line-height: 26px;
  }
}

.infoContainer {
  background-color: white;
  width: 9.2rem;
  height: auto;
  margin: 0px auto;
}

.container-imgs {
  touch-action: none;
  overflow: hidden;
  background-color: white;
  width: @container_width + 0.4rem;
  height: auto;
  margin: 0px auto;
  border-radius: 10px;
}

.container-normal {
  overflow: hidden;
  background-color: white;
  width: @container_width;
  height: auto;
  margin: 0px auto;
  border-radius: 10px;

  .header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .norad {
    border-radius: 0px;
  }

  .foot {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
}

.add-Margin-Top {
  margin-top: 20px;
}

.firstTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 10px;
}

.leftTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 30px;
}

.popupTitle {
  font-size: 13px;
  color: #ea5600;
  font-weight: bold;
  margin-left: 0.5rem;
  margin-bottom: 4px;
  margin-top: 18px;
}

.inputPrice {
  float: left;
  width: 35%;
  height: 46px;
  line-height: 46px;
  font-size: 15px;
  font-weight: bold;
  border: none;
}

.priceUnit {
  color: rgba(153, 153, 153, 0.45);
  padding-left: 3px;
  float: left;
  font-size: 15px;
  font-weight: bold;
  line-height: 47px;
}

.singleBox {
  background-color: white;
  width: 223px;
  height: 47px;
  border-radius: 0.26667rem;
  float: left;
  margin-left: 15px;
}

.maker {
  color: #999999;
  float: left;
  //padding-left: 14px;
  //padding-right: 8px;
  font-size: 28px;
  line-height: 46px;
}

.blockTitle {
  white-space: nowrap;
  overflow: hidden;
  float: left;
  font-size: 15px;
  font-weight: bold;
  width: 27%;
  line-height: 47px;
}

.blockContent {
  overflow: hidden;
  //float: left;
  font-size: 15px;
  font-weight: normal;
  width: 27%;
  line-height: 46px;
}

.content-divide {
  float: left;
  font-size: 14px;
  color: #efefef;
  margin: 0 10px;
  line-height: 47px;
}

.redColor {
  color: red;
}

.orangeColor {
  color: #ff3c00;
}

.graycolor {
  color: #bdbbbb;
}

.hardFit-button {
  margin-top: 13px;
  margin-right: 15px;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  height: 32px;
  line-height: 32px;
  width: auto;
  color: white;
  font-weight: 250;
  background: linear-gradient(to right, #7BC7FF 0px, #3B98FF 100%) repeat scroll 0% 0%;
}

.softFit-button {
  margin-right: 15px;
  border-radius: 10px;
  border: none;
  margin-left: auto;
  height: 32px;
  line-height: 32px;
  width: auto;
  color: white;
  font-weight: 250;
  background: linear-gradient(to right, #7BC7FF 0px, #3B98FF 100%) repeat scroll 0% 0%;
}

.sameTip {
  line-height: 46px;
  font-size: 10px;
  float: right;
  margin-right: 22px;
}

.samPic {
  width: 15px;
  padding-right: 3px;
}

.saveButton_Enable {
  // background-color: rgba(184, 184, 184, 0.2);
  float: right;
  width: 100px;
  height: 1.1rem;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 30px;
  font-size: 15px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
}

.saveButton_TotalEnable {
  // background-color: rgba(184, 184, 184, 0.2);
  float: right;
  width: 345px;
  height: 1.1rem;
  border-radius: 8px;
  margin-top: 10px;
  margin-right: 20px;
  margin-bottom: 30px;
  font-size: 15px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
}

.saveProjectButton_Enable {
  width: 9rem;
  height: 1.15rem;
  border-radius: 8px;
  margin-left: 18px;
  margin-top: 40px;
  margin-bottom: 25px;
  font-size: 15px;
  color: white;
  line-height: 1.22rem;
  text-align: center;
  background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
}

input::-webkit-input-placeholder { /*Webkit browsers*/
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/*  Mozilla Firefox 4-18使用伪类 */
input:-moz-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/* Mozilla Firefox 19+ 使用伪元素  */
input::-moz-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

/* IE10使用伪类 */
input:-ms-input-placeholder {
  color: #D9D9D9;
  font-size: 16px;
  font-weight: normal;
}

.part-inputpart {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  overflow: hidden;
  font-size: 15px;
}

.part-start {
  margin-left: 22px;
  float: left;
  font-weight: 900;
}

.part-end {
  margin-left: 22px;
  float: left;
  font-weight: 900;
}

.part-inputpart-row-graytext {
  color: #d8d8d8;
  width: 100%;
}

.part-inputpart-row-redtext {
  width: 100%;
  color: #ff5d3b;
}

.part-inputpart-row-startPeriod {
  line-height: 21px;
  /*padding-left: 120px;*/
}

.part-inputpart-row-right {
  float: right;
  margin-right: 23px;
}

.part-inputpart-row-right-timepick {
  width: 20px;
}

.part-inputpart-row-endPeriod {
  line-height: 21px;
  /*padding-left: 120px;*/
}

.part-inputpart-dropDownList {
  //height: 23%;
  /*垂直方向滚动*/
  /*overflow-y: scroll;*/
  overflow: auto;
  width: 100%;
  position: absolute;
  z-index: 99;
}

.part-uploadpic-content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 15px;
  background-color: white;
  padding-top: 8px;
  border-radius: 8px;
}

.part-inputpart-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  background-color: #ffffff;
  margin-top: 2px;
}

.content-none {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #c7c7c7;
}

.content-have {
  padding: 3px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: #ff5d3b;
}

.part-inputpart-row-header {
  white-space: nowrap;
  font-weight: 900;
  width: 150px;
}

.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
    }

    .block {
        width: 76%;
        height: 21.5%;
        background-color: #fff;
        border-radius: 8px;
        overflow: hidden;
    }
    /*    获取密码DIV*/
    .getPassword{
        background: url("../../assets/images/ContactPerson-img.png") no-repeat center center,
        linear-gradient(to right,#FFC274 ,#FF5D3B);
        text-align: center;
        height: 50px;
        line-height: 53px;
        color: white;
        background-size: 100%;
        font-size: 16px;
    }
    /*    门锁密码*/
    .LockPassWord{
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        line-height: 14px;
    }

    /*传入的密码值*/
    .password{
        color: #ff8247;
        font-size: 20px;
        font-family: SimSun;
    }
    /*关闭按钮*/
    .closeImg{
        margin-top: 50px;
        height: 30px;
        width: 30px;
        border: 1px solid white;
        border-radius: 50%;
        background: url("../../assets/images/GetDoorPassword-closeImg.png") no-repeat center center;
        background-size:18px 18px;
    }
    .van-overlay{
            background-color: rgba(80,80,80,.7);;
    }
    .contract{
        position: relative;
        margin: 0 0 15px 0;
        height: auto;
         background-color: white;
        border-radius: 8px;
        overflow: hidden;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .contract-text{
      width:40%;
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
        display:inline-block;
    }
    .position{
        margin: 15px 50px 0 15px;
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space:nowrap;
        color:black;
    }
    .top-title-green{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#63cb96,#30d0ac);
        color: white;
    }
    .top-title-red{
        margin: 0;
        width: 45px;
        height: 20px;
        font-size: 10px;
        text-align: center;
        line-height: 22px;
        border-radius: 8px 0;
        background: linear-gradient(to right,#fc401b,#e02321);
        color: white;
    }
    .choose{
        width: 100%;
        height: 56px;
        background-color: white;
        display: flex;
    }
    .choose-one{
        width: 187px;
        text-align: center;
    }
    .choose-one-textActive{
        font-size: 14px;
        color: #fe5e3a;
        font-weight: bold;
        margin-top: 10px;
    }
    .choose-one-text{
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
    }
    .choose-one-icon{
        width: 20px;
        height: 4px;
        border-radius: 3px;
        background-color: #fe5e3a;
        margin:0 auto;
    }
    .choose-line{
        width: 1px;
        height: 17px;
        margin-top: 13px;
        background-color: #e0e0e0;
    }
    .saveButton_Disable {
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 15px 80px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .contract-textLong{
        display:inline-block;
        width:100%;
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
    }
/*列表详情*/
.listDetail {
  position: relative;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  padding-bottom: 0.5rem;
  padding-top:0.5rem;
}

.listDetail:after {
  content: "";
  display: block;
  clear: both;
}

/*    申请人信息DIV*/
.proposerDiv {
  margin-left: 0.5rem;
  font-size: 13px;
  color: #999999;
  float: left;
}

/*分割线1*/
.rule1 {
  position: absolute;
  left: 2.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  float: left;
}

/*分割线2*/
.rule2 {
  position: absolute;
  left: 5.3rem;
  top: 0.1rem;
  border: 0.01rem solid #f7f7f7;
  height: 1rem;
  width: 0.01rem;
  float: left;
}

/*装修预算和改造户型*/
.budgetDiv, .remouldDiv {
  font-size: 13px;
  color: #999999;
  margin-left: 1.68rem;
  float: left;
}

/*   装修预算 */
.budgetDiv {
  width: 1.5rem;
  text-align: center;
}

/*    分割线2*/
/* 申请人取值DIV*/
.proposerValueDiv {
  margin-top: 0.3rem;
  font-size: 14px;
  color: #FF5D3B;
  height: 0.5rem;
  float: left;
  /*margin-left: 0.6rem;*/
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

/*装修预算和改造户型取值*/
.budgetValueDiv, .remouldValueDiv {
  font-size: 14px;
  color: #FF5D3B;
  margin-top: 0.3rem;
  float: left;
  width: 2rem;
  /*border: 1px solid red;*/
  text-align: center;
}

.budgetValueDiv {
  margin-left: 0.8rem;
}

.remouldValueDiv {
  margin-left: 1.3rem;
}

/*    分割线3*/
.rule3 {
  border: 0.01rem solid #efefef;
  width: 100%;
  height: 0rem;
  margin-top: 0.1rem;
}
.createMethodsDiv {
  font-size: 13px;
  float: left;
  color: #999999;
}

/*    尾部 创建时间的createTimeDiv*/
.createTimeDiv {
  font-size: 13px;
  float: left;
  margin-left: 0.2rem;
  color: #999999;
  /*margin-top: 0.05rem;*/
}
.btn1, .btn2 {
  float: right;
  margin-right: 0.7rem;
  height: 0.82rem;
  width: 2.3rem;
  border-radius: 0.2rem;
  border: 0rem solid transparent;
  box-shadow: none;
  line-height: 0.7rem;
  font-size: 13px;
}
.btn2 {
  padding: 0.01rem;
  border: 0.03rem solid transparent;
  background-image: linear-gradient(white, white),
  linear-gradient(to right, #FFC274, #FF5D3B);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  color: #FF5D3B;
}
/*列表头部红色重点信息*/
.headSpan {
  float: left;
  margin-right: 0.1rem;
  color: #FF5D3B;
}

/*列表头部小圆点*/
.dot {
  border-radius: 50%;
  width: 0.01rem;
  height: 0.01rem;
  border: 0.05rem solid black;
  float: left;
  margin-top: 0.24rem;
  margin-right: 0.1rem;
}

/*列表头部信息*/
.headInfo {
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  font-size: 15px;
  font-weight: bold;
}
.stateList {
  border-radius: 0.3rem;
  width: 93%;
  margin-left: 0.35rem;
  position: relative;
  margin-bottom: 0.4rem;
  background-color: #ffffff;
  padding-bottom: 0.5rem;
}
/*    尾部DIV*/
.listFloor {
  margin-left: 0.5rem;
  margin-top: 0.35rem;
  height: 0.73rem;
  line-height: 0.73rem;
}

.listFloor:after {
  content: "";
  display: block;
  clear: both;
}
.stateUnapproval, .statePassed, .stateRejected {
  height: 0.45rem;
  width: fit-content;
  padding: 0 0.13rem;
  border-radius: 0.2rem 0rem;
  font-size: 11px;
  text-align: center;
  color: #ffffff;
  line-height: 0.5rem;
}
/*列表状态样式 1是未审批*/
.stateUnapproval {
  background: -webkit-linear-gradient(left, #FFC274, #FF5D3B);
}

/*列表状态样式 2是已通过*/
.statePassed {
  background: -webkit-linear-gradient(left, #66CB94, #28D3B0);
}

/*列表状态样式 3是已驳回*/
.stateRejected {
  background: -webkit-linear-gradient(left, #E02020, #FF431B);
}
.contracts{
  margin-top:12px;
}
    .potential {
        width: 375px;
        /*background-color: #F8F8F8;*/

        box-sizing: border-box;
        overflow: hidden;
        color: #252525;


        .sub_btn {
            margin: 30px 0 70px;

            .sub_button {
                border-radius: @redius;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

                &.not_choose {
                    background: none;
                    background-color: rgba(184, 184, 184, 0.2);
                }
            }
        }

        p {
            margin: 0px;
        }

        .application_time {
            text-align: right;
            width: 345px;
            font-size: 12px;
            color: rgb(136, 136, 136);
            padding: 2px 0 5px;
        }

        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 25px;
            font-weight: 700;
            font-size: 12px;
        }

        .overDiv {
            height: 23px;
            /*line-height: 10px;*/
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            /*justify-content: center;*/
        }

        /*实际结束更改*/

        .overDiv > div {
            font-weight: 900;
        }

        .overDiv > span {
            font-size: 13px;
            color: rgb(110, 110, 110);
        }

        .calendar_icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../../assets/images/timepick.png) no-repeat center center;
            background-size: cover;
            margin-left: 10px;
            background-position-y: -1px;
        }
        .panel {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/

            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            border-bottom: 1px solid #e0e0e0;

            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;
                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }

        .panels {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/

            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;


            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;
                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }
        .radio_body {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            .dot {
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: rgb(136, 136, 136);
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 6px;

                &.is_dot {
                    background-color: rgb(251, 91, 64);
                }
            }

            .line {
                width: 2px;
                height: 12px;
                background-color: rgb(250, 250, 250);
            }

            .choose {
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .choose_btn {
                    padding: 5px;
                    margin: 10px;
                    color: rgb(136, 136, 136);
                    cursor: pointer;

                    &.pass {
                        color: rgb(251, 91, 64);
                        font-weight: 900;
                    }

                    &.reject {
                        color: red;
                        font-weight: 900;
                    }
                }
            }
        }


        .t_table {
            .t_header {
                text-align: center;
                color: rgb(136, 136, 136);
                /*font-weight: 900;*/
                font-size: 10px;
                height: 40px;
                line-height: 40px;
            }

            .t_title {
                background-color: rgb(244, 249, 255);
                color: #33ADFF;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                height: 20px;
            }

            .t_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }

        .displayBlock {
            //position: relative;
            overflow: hidden;
            background-color: white;
            border-radius: 9px;
            width: 9rem;
            height: auto;
            margin: 19px auto;
        }

        .swiper_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            /*设计图片部分的css*/

            .part {
                background-color: white;
                border-radius: 8px;
                margin: 15px;

            }


            /*css*/

            .part-subtitle {
                color: #ff5d3b;
                font-size: 12px;
                font-weight: 900;
                position: relative;
                top: 12px;
            }

            /*css*/

            .part-inputpart {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                overflow: hidden;
                font-size: 15px;
            }


            /*css*/

            .part-uploadpic-module {
                margin: 10px 25px 10px 10px;
            }

            /*css*/

            .part-uploadpic-text {
                font-size: 15px;
                margin-left: 24px;
            }

            /*css*/

            .part-uploadpic-module-pic {
                width: 80px;
                height: 80px;
                border-radius: 8px;
            }


            // 图片墙
            .swiper_show01 { //参数
                width: 9rem;
                position: relative;
                margin: 10px 10px;
                height: 78px;

                .content01 {
                    position: absolute;
                    display: flex;
                    justify-content: space-around;
                    left: 0px;

                    .imgWrap01 {
                        margin-right: 10px;
                    }
                }

                .num01 {
                    position: absolute;
                    left: 8px;
                    top: 15px;
                    font-size: 10px;
                    background-color: rgb(0, 0, 0);
                    opacity: .2;
                    color: rgb(255, 255, 255);
                    padding: 2px 6px;
                    display: inline-block;
                    border-radius: 10px;
                    font-weight: 900;
                    line-height: 10px;
                }
            }

            .displayBlock01 { //参数
                //position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 9px;
                width: 9rem;
                height: auto;
                margin: 19px auto;
            }


            /*tab部分*/
            // 循环表单留着
            .t_table {
                .t_header {
                    text-align: center;
                    color: rgb(136, 136, 136);
                    font-weight: 900;
                    font-size: 10px;
                    height: 40px;
                    line-height: 40px;
                }

                .t_title {
                    background-color: rgb(244, 249, 255);
                    color: rgb(39, 135, 248);
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                }

                .t_td {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }

                /*tab关联部分css*/

                .fuck {
                    margin: 15px;
                }

                /*css*/

                .part-inputpart {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                }

                .part-inputpart-dropDownList-option-selected {
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    background-color: #ffffff;
                    margin-top: 2px;
                }

                /*参数*/

                .part-inputpart-row-header {
                    font-weight: 900;
                }


                .part-inputpart-row input {
                    border: 0;
                    //width: 100%;
                    flex: auto;
                }


                /*css参数*/

                .part-inputpart-dropDownList {
                    height: 23%;
                    /*垂直方向滚动*/
                    /*overflow-y: scroll;*/
                    overflow: auto;
                    width: 92.5%;
                    position: absolute;
                    z-index: 99;
                }

                /*css参数*/

                .part-inputpart-dropDownList-option {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    line-height: 30px;
                    background-color: #f5f5f5;
                    font-size: 12px;
                    border-bottom: 1px white solid;
                    color: #999999;
                }


                /*参数*/

                .part-inputpart-row-enablenon {
                    margin-left: 26px;
                }

                /*参数*/

                .part-inputpart-row-graytext {
                    color: #d8d8d8;
                    //width: 100%;
                    flex: auto;
                }

                .part-inputpart-row-normaltext {
                    color: black;
                    flex: auto;
                }

                .part-inputpart-row-redtext {
                    //width: 100%;
                    flex: auto;
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row-right {
                    float: right;
                    margin-right: 10px;
                }

                .part-inputpart-row-right-rightArrow {
                    width: 8px;
                }

                .part-inputpart-row-right-downArrow {
                    width: 9px;
                }

                .part-inputpart-row-right-upArrow {
                    width: 9px;
                    transform: rotateX(180deg);
                }

                .part-inputpart-row-right-timepick {
                    width: 20px;
                }

                .content-none {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }

                /*参数*/

                .content-divide {
                    font-size: 12px;
                    color: #efefef;
                    margin: 0 10px;
                }

            }

        }
    }
    .bottom-property{
        float: right;
        width: 82px;
        height: 36px;
        margin-top: 15px;
        margin-left: 11px;
        margin-bottom: 15px;
        margin-right:11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
		.bill {
			margin-top: 15px;

			.bill_title {
				font-size: 12px;
				background-color: rgb(241, 241, 241);
				color: rgb(155, 155, 155);
				padding: 1px 15px;
			}

			.bill_panel {
				background-color: rgb(255, 255, 255);


				.bill_info {
					position: relative;

					/*padding: 15px;*/
					padding: 0 15px;
					border-bottom: 1px solid rgb(250, 250, 250);

					.img_icon {
						position: absolute;
						top: 0;
						right: -6px;
					}

					&:nth-last-child {
						border-bottom: none;
					}

					.bill_money {
						font-size: 15px;

						&.unreceived {
							opacity: .3;
						}
					}

					p {
						font-weight: bolder;
						font-size: 14px;
						//padding-top: 11px;

						&:last-of-type {
							//padding-bottom: 10px;
						}
					}

					span {
						font-weight: normal;
						color: rgb(151, 151, 151);
					}
				}
			}
		}

		.billPayTime{
			color: rgb(151, 151, 151);
			height: 18px;
			line-height: 18px;
		}
	/* 未收图标 */
	.laber {
		width: 0px;
		height: 0px;
		border-top: 0px solid #FE8D68;
		border-right: 44.6px solid #FE8D68;
		border-bottom: 44.6px solid transparent;
		border-left: 30px solid transparent;
		position: absolute;
		top: 0px;
		right: 0px;


	}

	/* 旋转文字 */
	.laberText {
		width: 33px;
		height: 47px;
		transform: rotate(45deg);
		font-size: 12px;
		color: #ffffff;
	}
	.rentPriceA {
		color: rgba(252, 213, 204, 1);
		font-weight: 500;
	}
		.total_color {
			color: rgba(255, 93, 59, 1) !important;
		}

.imgWrap {
    margin-right: 10px;
    margin-left: 10px;
}
.deletePictures{
  font-size: 13px;;
  text-align: center;
  color: rgba(255, 93, 59, 1) !important;
}
</style>
<style>

    .updateProgress .van-cell{
        background-color:#f8f8f8 !important;
    }
    .updateProgress .van-collapse-item__content{
        background-color:#f8f8f8 !important;
        padding:0 !important;
    }
</style>
